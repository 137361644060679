export default {
  "footer_company": "Rovin",
  "title": "Rovin IoT Platform",
  "webshop": "bluecherry",
  "header_logo": "rovin_logo.svg",
  "sidenav_logo": "rovin_logo.svg",
  "sidenav_text": "<h1> <span>Connect.</span> <strong>Anything.</strong> <span>Anywhere.</span> </h1>",
  "use_proxy": true,
  "show_footer_logo": true,
  "company": "Rovin"
}

